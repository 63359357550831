/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable complexity */
import {
  CalculationResult,
  CalculationResultTotal,
  Declaration,
  DeclarationCustomsType,
  DeclarationStatus,
  EnvironmentCountries,
  NlDecoMessageType,
} from '@e-origin/shared';

import { Button } from '../../../../components';
import { ControlNotification } from '../../../../components/declaration-details/control-notification/control-notification.component';
import { ControlResultDetails } from '../../../../components/declaration-details/control-result/control-result-details.component';
import { ExitControlResultNotification } from '../../../../components/declaration-details/exit-control-result/exit-control-result-notification.component';
import { useConfig } from '../../../../hooks/use-config';
import { invalidateOneHighValue, invalidateOneLowValue, selectDetails } from '../../../../stores/declarationsSlice';
import {
  BusinessRejectionRow,
  CalculationItem,
  CalculationItems,
  Calculations,
  Container,
  ErrorSection,
  FunctionalErrorItem,
  FunctionalErrorRow,
  FunctionalErrorTitle,
  Header,
  InvalidationDecisionContainer,
  InvalidationDecisionStatusRow,
  InvalidationInfoContainer,
  InvalidationRequestForm,
  RejectionInfo,
} from './declaration-details-customs.style';

interface IDeclarationDetailsCustoms {
  status?: string;
}

interface CalculationsDisplayValues {
  items: CalculationResult[];
  total: CalculationResultTotal[];
}

interface CalculationsDisplay {
  duties: CalculationsDisplayValues;
  vat: CalculationsDisplayValues;
}

const canDisplayRequestInvalidationButton = (
  declarationDetails: Declaration,
  configCountry: EnvironmentCountries,
): boolean => {
  const isReleasedWithoutInvalidationRequest =
    declarationDetails.generalInfo.status === DeclarationStatus.RELEASED &&
    !declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate;

  if (isReleasedWithoutInvalidationRequest) return true;

  if (configCountry === EnvironmentCountries.NL) {
    return canRequestInvalidationForNL(declarationDetails);
  }

  if (declarationDetails.generalInfo.group === 'H1') {
    return (
      [DeclarationStatus.RELEASED, DeclarationStatus.ACCEPTED].includes(declarationDetails.generalInfo.status) &&
      !declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate
    );
  }

  return false;
};

const canRequestInvalidationForNL = (declarationDetails: Declaration) => {
  const lastReceivedMessage = declarationDetails.customsState?.customsLogs?.slice(-1)[0];

  const hasInvalidationRequestWithSpecificMessageType =
    declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate &&
    [NlDecoMessageType.XMLCNT, NlDecoMessageType.CC456A].includes(
      lastReceivedMessage?.customsMessageCode as NlDecoMessageType,
    );

  if (
    hasInvalidationRequestWithSpecificMessageType ||
    (!declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate &&
      [NlDecoMessageType.CC426A, NlDecoMessageType.CC428A].includes(
        lastReceivedMessage?.customsMessageCode as NlDecoMessageType,
      ))
  ) {
    return true;
  }

  return false;
};

/* eslint-disable max-statements */
const DeclarationDetailsCustoms: React.FC<IDeclarationDetailsCustoms> = (props) => {
  const declarationDetails = useSelector(selectDetails);
  const [invalidationRequestReason, setInvalidationRequestReason] = useState(
    declarationDetails?.customsState?.invalidationRequest?.invalidationReason || '',
  );

  const dispatch = useDispatch();
  const { config } = useConfig();

  const handleInvalidationReasonChange = (e: any) => {
    setInvalidationRequestReason(e.target.value);
  };

  const handleSendInvalidationRequest = () => {
    if (declarationDetails.generalInfo.group === 'H1') {
      dispatch(invalidateOneHighValue(invalidationRequestReason));
    } else {
      dispatch(invalidateOneLowValue(invalidationRequestReason));
    }
  };

  const calculations: CalculationsDisplay = {
    duties: { items: [], total: [] },
    vat: { items: [], total: [] },
  };

  ['items', 'total'].forEach((type) => {
    declarationDetails?.customsState?.calculationResults?.[type]?.forEach((item: any) => {
      if (item.taxType === 'A00') {
        calculations.duties[type].unshift(item);
        return;
      }
      calculations[item.taxType === 'B00' ? 'vat' : 'duties'][type].push(item);
    });
  });

  if (!declarationDetails?.customsState) {
    return <div>Not sent yet</div>;
  }
  return (
    <Container>
      <Header>
        <div>
          <label>Status</label>
          <p>{props.status}</p>
        </div>
        <div>
          <label>Correlation ID</label>
          <p>{declarationDetails.customsState?.corelationId || 'N/A'}</p>
        </div>
        {declarationDetails.customsState?.movementReference && (
          <div>
            <label>Movement reference number</label>
            <p>{declarationDetails.customsState?.movementReference}</p>
          </div>
        )}
        {declarationDetails.customsState?.dateOfAcceptance && (
          <div>
            <label>Date of acceptance</label>
            <p>{declarationDetails.customsState?.dateOfAcceptance}</p>
          </div>
        )}
        {declarationDetails.customsState?.releaseForImport?.dateOfRelease && (
          <div>
            <label>Date of release</label>
            <p>{declarationDetails.customsState?.releaseForImport?.dateOfRelease}</p>
          </div>
        )}
        {declarationDetails.customsState?.noRelease?.rejectionDate && (
          <div>
            <label>Rejection date</label>
            <p>{declarationDetails.customsState?.noRelease?.rejectionDate}</p>
          </div>
        )}
      </Header>
      {Object.keys(calculations).map(
        (type) =>
          !!(calculations[type].items.length || calculations[type].total.length) && (
            <Calculations key={type}>
              <CalculationItems>
                <h3>{type === 'duties' ? 'Duties' : 'VAT'}</h3>
                {calculations[type].items.map((item: CalculationResult, idx) => (
                  <CalculationItem key={idx}>
                    <div>
                      <label>Tax Type</label>
                      <p>{item.taxType}</p>
                    </div>
                    {item.taxBase && (
                      <div>
                        <label>Tax base value</label>
                        <p>{item.taxBase.amount}€</p>
                      </div>
                    )}
                    {item.taxBase && (
                      <div>
                        <label>Rate</label>
                        <p>{item.taxBase.taxRate}%</p>
                      </div>
                    )}
                    <div>
                      <label>Amount due</label>
                      <p>{item.payableTaxAmount}€</p>
                    </div>
                    <div>
                      <label>Payment method</label>
                      <p>{item.paymentMethod}</p>
                    </div>
                  </CalculationItem>
                ))}
              </CalculationItems>

              <CalculationItems>
                <h3>{type === 'duties' ? 'Total Duties' : 'Total VAT'}</h3>
                {calculations[type].total.map((item: CalculationResultTotal, idx) => (
                  <CalculationItem key={idx}>
                    <div>
                      <label>Tax Type</label>
                      <p>{item.taxType}</p>
                    </div>
                    <div>
                      <label>Total amount due</label>
                      <p>{item.payableTaxAmount}€</p>
                    </div>
                    <div>
                      <label>Payment method</label>
                      <p>{item.paymentMethod}</p>
                    </div>
                  </CalculationItem>
                ))}
              </CalculationItems>
            </Calculations>
          ),
      )}
      {declarationDetails.customsState?.inControl && (
        <ControlNotification inControl={declarationDetails.customsState?.inControl} />
      )}
      {[DeclarationStatus.RELEASED, DeclarationStatus.ACCEPTED].includes(declarationDetails.generalInfo?.status) && (
        <InvalidationInfoContainer>
          <InvalidationRequestForm>
            <label>Invalidation reason</label>
            <textarea
              value={invalidationRequestReason}
              onChange={handleInvalidationReasonChange}
              disabled={
                declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate !== undefined &&
                declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate.length > 0
              }
            />
            {canDisplayRequestInvalidationButton(declarationDetails, config?.COUNTRY) && (
              <div>
                <Button
                  type="button"
                  primary
                  disabled={!invalidationRequestReason.length}
                  onClick={handleSendInvalidationRequest}
                >
                  Request invalidation
                </Button>
              </div>
            )}
          </InvalidationRequestForm>
          {declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate !== undefined &&
            declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate.length > 0 && (
              <div>
                <label>Invalidation request date</label>
                <p>{declarationDetails.customsState?.invalidationRequest?.invalidationRequestDate}</p>
              </div>
            )}
        </InvalidationInfoContainer>
      )}
      {(declarationDetails.customsState.sentTo !== DeclarationCustomsType.PLDA ||
        declarationDetails.generalInfo.status === DeclarationStatus.REJECTED) && (
        <RejectionInfo>
          {declarationDetails.customsState.customsDeclarationRejection && (
            <ErrorSection>
              <BusinessRejectionRow>
                <div>
                  <label>Business rejection type</label>
                  <p>{declarationDetails.customsState.customsDeclarationRejection.businessRejectionType}</p>
                </div>
              </BusinessRejectionRow>
              <BusinessRejectionRow>
                <div>
                  <label>Rejection date</label>
                  <p>{declarationDetails.customsState.customsDeclarationRejection.rejectionDate}</p>
                </div>
                {declarationDetails.customsState.customsDeclarationRejection.rejectionCode && (
                  <div>
                    <label>Rejection code</label>
                    <p>{declarationDetails.customsState.customsDeclarationRejection.rejectionCode}</p>
                  </div>
                )}
                {declarationDetails.customsState.customsDeclarationRejection.rejectionReason && (
                  <div>
                    <label>Rejection reason</label>
                    <p>{declarationDetails.customsState.customsDeclarationRejection.rejectionReason}</p>
                  </div>
                )}
              </BusinessRejectionRow>
            </ErrorSection>
          )}
          {declarationDetails.customsState?.functionalError?.length > 0 && (
            <ErrorSection>
              {declarationDetails.customsState.functionalError.map((item) => (
                <FunctionalErrorRow key={`functional-error-${item.sequenceNumber}`}>
                  <FunctionalErrorTitle>{item.type || 'Functional error'}</FunctionalErrorTitle>
                  <FunctionalErrorItem>
                    {item.sequenceNumber && (
                      <div>
                        <label>Sequence number</label>
                        <p>{item.sequenceNumber}</p>
                      </div>
                    )}
                    <div>
                      <label>Error code</label>
                      <p>{item.errorCode}</p>
                    </div>
                  </FunctionalErrorItem>
                  <FunctionalErrorItem>
                    <div>
                      <label>Error pointer</label>
                      <p>{item.errorPointer}</p>
                    </div>
                  </FunctionalErrorItem>
                  <FunctionalErrorItem>
                    <div>
                      <label>Error reason</label>
                      <p>{item.errorReason}</p>
                    </div>
                  </FunctionalErrorItem>
                  {item.remarks && item.remarks.length && (
                    <FunctionalErrorItem>
                      <div>
                        <label>Remarks</label>
                        <p>{item.remarks}</p>
                      </div>
                    </FunctionalErrorItem>
                  )}
                  {item.originalAttributeValue && item.originalAttributeValue.length && (
                    <FunctionalErrorItem>
                      <div>
                        <label>Original Attribute</label>
                        <p>{item.originalAttributeValue}</p>
                      </div>
                    </FunctionalErrorItem>
                  )}
                </FunctionalErrorRow>
              ))}
            </ErrorSection>
          )}
        </RejectionInfo>
      )}
      {declarationDetails.generalInfo?.status === DeclarationStatus.INVALIDATED && (
        <InvalidationDecisionContainer>
          <InvalidationDecisionStatusRow>
            <div>
              <label>Invalidation decision date</label>
              <p>{declarationDetails.customsState?.invalidationDecision?.invalidationDecisionDate}</p>
            </div>
            <div>
              <label>Invalidation request date</label>
              <p>{declarationDetails.customsState?.invalidationDecision?.invalidationRequestDate}</p>
            </div>
            <div>
              <label>Invalidation initiated by customs</label>
              <p>{declarationDetails.customsState?.invalidationDecision?.invalidationInitiatedByCustoms}</p>
            </div>
          </InvalidationDecisionStatusRow>
          <InvalidationRequestForm>
            <label>Invalidation reason</label>
            <textarea
              value={
                declarationDetails.customsState?.invalidationDecision?.invalidationReason ||
                declarationDetails.customsState?.invalidationRequest?.invalidationReason
              }
              disabled
            />
          </InvalidationRequestForm>
        </InvalidationDecisionContainer>
      )}
      {declarationDetails.generalInfo?.status === DeclarationStatus.NOT_RELEASED &&
        declarationDetails.customsState?.controlResult && (
          <ControlResultDetails controlResult={declarationDetails.customsState?.controlResult} />
        )}
      {declarationDetails.generalInfo?.status === DeclarationStatus.EXPORTED &&
        declarationDetails.customsReport?.exitControlResult && (
          <ExitControlResultNotification exitControlResult={declarationDetails.customsReport.exitControlResult} />
        )}
    </Container>
  );
};

export default DeclarationDetailsCustoms;
