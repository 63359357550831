/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { MultilingualCodeSystems, TransitGroup, TransitHouseConsignment } from '@e-origin/shared';

import { Input, MultiLangCodes } from '../../../../../../../../components';
import { selectTransit } from '../../../../../../../../stores/transitsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../../../styles/common';
import { TransitAutocompleteHeader, TransitDuplicateRow, TransitInputHeader } from '../../../../../../components';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';

interface GoodsItemDataProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  disabled: boolean;
  editDisabledForConsignment: boolean;
}

export const GoodsItemData: React.FC<GoodsItemDataProps> = (props) => {
  const { form } = props;
  const transit = useSelector(selectTransit);
  const isArrival = transit.generalInfo?.group === TransitGroup.ARRIVAL;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <TransitAutocompleteHeader
              form={form}
              parents={[transit.TransitOperation.declarationType]}
              name="declarationType"
              disabled={props.disabled}
              width={32.5}
              placeholder="Declaration Type"
              code="CL231"
            />
            {!isArrival && (
              <TransitAutocompleteHeader
                form={form}
                parents={[transit.Consignment.countryOfDispatch, props.houseConsignment?.countryOfDispatch]}
                name="countryOfDispatch"
                disabled={props.disabled}
                width={31}
                placeholder="Country Of Dispatch"
                code="CL008"
              />
            )}
            <TransitAutocompleteHeader
              form={form}
              parents={[transit.Consignment.countryOfDestination]}
              name="countryOfDestination"
              disabled={props.disabled}
              width={32.5}
              placeholder="Country Of Destination"
              code="CL008"
            />
            {!isArrival && (
              <TransitInputHeader
                form={form}
                parents={[transit.Consignment.referenceNumberUCR, props.houseConsignment?.referenceNumberUCR]}
                name="referenceNumberUCR"
                width={25}
                placeholder="Reference Number UCR"
                disabled={props.disabled}
              />
            )}
          </FormRow>
          <FormRow>
            <Input
              name="Commodity.descriptionOfGoods"
              placeholder="Description of goods"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.descriptionOfGoods}
              width={33}
              widthUnit="%"
              compact
              disabled={props.editDisabledForConsignment}
            />
            <Input
              name="Commodity.CommodityCode.hsCode"
              placeholder="HS code"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.CommodityCode.hsCode}
              width={33}
              widthUnit="%"
              compact
              disabled={props.editDisabledForConsignment}
            />
            <MultiLangCodes
              name="Commodity.CUSCode"
              placeholder="CUS code"
              onChange={form.handleChange}
              value={form.values.Commodity.CUSCode}
              width={33}
              code="CL016"
              system={MultilingualCodeSystems.NCTS}
              compact
              disabled={props.editDisabledForConsignment}
            />
          </FormRow>
          <TransitDuplicateRow
            addButtonLabel="Add new UNNumber"
            form={form}
            inputs={[
              {
                type: 'MultiLang',
                label: 'UNNumber',
                name: 'UNNumber',
                width: 25,
                code: 'UnDangerousGoodsCode',
              },
            ]}
            compact
            arrayPath="Commodity.DangerousGoods"
            disabled={props.disabled}
          />
          <FormRow>
            <Input
              name="Commodity.GoodsMeasure.grossMass"
              placeholder="Gross mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.grossMass}
              width={30}
              widthUnit="%"
              compact
              disabled={props.editDisabledForConsignment}
            />
            <Input
              name="Commodity.GoodsMeasure.netMass"
              placeholder="Net mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.netMass}
              width={32}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            {!isArrival && (
              <Input
                name="Commodity.GoodsMeasure.supplementaryUnits"
                placeholder="Supplementary Units"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.Commodity.GoodsMeasure.supplementaryUnits}
                width={32}
                widthUnit="%"
                compact
                disabled={props.disabled}
              />
            )}
          </FormRow>
          <TransitDuplicateRow
            addButtonLabel="Add new package"
            form={form}
            inputs={[
              {
                type: 'MultiLang',
                label: 'Type of Packages',
                name: 'typeOfPackages',
                code: 'CL017',
              },
              {
                type: 'Input',
                label: 'Number of Packages',
                name: 'numberOfPackages',
              },
              {
                type: 'Input',
                label: 'Marks',
                name: 'shippingMarks',
              },
            ]}
            arrayPath="Packaging"
            compact
            disabled={props.editDisabledForConsignment}
          />
          {!isArrival && (
            <FormRow>
              <Input
                name="Commodity.CommodityCode.initialAmountToPayForDuties"
                placeholder="Initial Amount To Pay For Duties"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.Commodity.CommodityCode.initialAmountToPayForDuties}
                width={36}
                widthUnit="%"
                compact
                disabled={props.disabled}
              />
              <Input
                name="Commodity.CommodityCode.initialAmountToPayForVat"
                placeholder="Initial Amount To Pay For VAT"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.Commodity.CommodityCode.initialAmountToPayForVat}
                width={34}
                widthUnit="%"
                compact
                disabled={props.disabled}
              />
            </FormRow>
          )}
        </FormSection>
      </FormContainer>
    </>
  );
};
