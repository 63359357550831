import { useMemo } from 'react';

import { TransitStatus } from '@e-origin/shared';

import { StatusIcons } from '../../../assets';
import * as Style from './transit-status-indicator.style';

interface TransitStatusIndicatorProps {
  status: TransitStatus;
  isArrival?: boolean;
}

const getStatusDescription = (status: TransitStatus): string => {
  const statusDescriptions: Record<TransitStatus, string> = {
    [TransitStatus.NOT_SENT]: 'Not sent, no risk analysis',
    [TransitStatus.SENDING]: 'Validation in progress',
    [TransitStatus.SENT]: 'Validation in progress',
    [TransitStatus.ACCEPTED]: 'Validation in progress',
    [TransitStatus.REJECTED]: 'Rejected',
    [TransitStatus.IN_CONTROL]: 'Validation in progress',
    [TransitStatus.CORR_REFUSED_WAITING]: 'Validation in progress',
    [TransitStatus.NOT_RELEASED]: 'Declarations not-released',
    [TransitStatus.CORR_OK_WAITING]: 'Validation in progress',
    [TransitStatus.RELEASED]: 'Released',
    [TransitStatus.FINISHED]: 'Finished',
    [TransitStatus.INVALIDATION_PENDING]: 'Validation in progress',
    [TransitStatus.INVALIDATED]: 'Declarations invalidated',
    [TransitStatus.GUARANTEE_NOT_VALID]: 'Declarations not-released',
    [TransitStatus.UNLOAD_PERMISSION]: 'Unload permission received',
    [TransitStatus.REMARKS_SENT]: 'Remarks sent',
    [TransitStatus.DISCREPANCIES]: 'Discrepancies',
    [TransitStatus.REQUESTED_NON_ARRIVED]: 'Requested info about non-arrived',
  };
  return statusDescriptions[status] || 'Unknown status';
};

const TransitStatusIndicator: React.FC<TransitStatusIndicatorProps> = ({ status, isArrival = false }) => {
  const statusIconMapper = useMemo(
    () =>
      new Map([
        [
          TransitStatus.NOT_SENT,
          {
            icon: StatusIcons.NOT_SENT,
            alt: 'NOT_SENT',
          },
        ],
        [
          TransitStatus.SENDING,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'SENDING',
          },
        ],
        [
          TransitStatus.SENT,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'SENT',
          },
        ],
        [
          TransitStatus.ACCEPTED,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'ACCEPTED',
          },
        ],
        [
          TransitStatus.REJECTED,
          {
            icon: StatusIcons.REJECTED,
            alt: 'REJECTED',
          },
        ],
        [
          TransitStatus.IN_CONTROL,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'IN_CONTROL',
          },
        ],
        [
          TransitStatus.CORR_REFUSED_WAITING,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'CORR_REFUSED_WAITING',
          },
        ],
        [
          TransitStatus.NOT_RELEASED,
          {
            icon: StatusIcons.NOT_RELEASED,
            alt: 'NOT_RELEASED',
          },
        ],
        [
          TransitStatus.CORR_OK_WAITING,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'CORR_OK_WAITING',
          },
        ],
        [
          TransitStatus.RELEASED,
          {
            icon: isArrival ? StatusIcons.RELEASED : StatusIcons.GREEN_SUCCESS,
            alt: 'RELEASED',
          },
        ],
        [
          TransitStatus.FINISHED,
          {
            icon: StatusIcons.RELEASED,
            alt: 'FINISHED',
          },
        ],
        [
          TransitStatus.INVALIDATION_PENDING,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'INVALIDATION_PENDING',
          },
        ],
        [
          TransitStatus.INVALIDATED,
          {
            icon: StatusIcons.INVALIDATED,
            alt: 'INVALIDATED',
          },
        ],
        [
          TransitStatus.REMARKS_SENT,
          {
            icon: StatusIcons.CUSTOMS_PROCESSING,
            alt: 'REMARKS_SENT',
          },
        ],
        [
          TransitStatus.UNLOAD_PERMISSION,
          {
            icon: StatusIcons.GREEN_SUCCESS,
            alt: 'UNLOAD_PERMISSION',
          },
        ],
        [
          TransitStatus.GUARANTEE_NOT_VALID,
          {
            icon: StatusIcons.NOT_RELEASED,
            alt: 'GUARANTEE_NOT_VALID',
          },
        ],
        [
          TransitStatus.DISCREPANCIES,
          {
            icon: StatusIcons.GREEN_REJECTED,
            alt: 'DISCREPANCIES',
          },
        ],
      ]),
    [isArrival],
  );

  const currentStatus = statusIconMapper.get(status);

  if (!currentStatus) {
    return null;
  }

  return (
    <Style.Container>
      <Style.SvgIcon title={getStatusDescription(status)}>
        <img src={currentStatus.icon} alt={currentStatus.alt} />
      </Style.SvgIcon>
    </Style.Container>
  );
};

export default TransitStatusIndicator;
