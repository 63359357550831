/* eslint-disable max-lines */
import { Transit, TransitDocumentsEnum, TransitGroup } from '@e-origin/shared';

export enum FormDocumentsInputType {
  NUMBER = 'NUMBER',
  INPUT = 'INPUT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  MULTI_LANG = 'MULTI_LANG',
  DATE = 'DATE',
}

export interface TransitDocumentFormSchemaProps {
  [TransitDocumentsEnum: string]: {
    fields: {
      [documentPropertyPath: string]: {
        type: FormDocumentsInputType;
        options?: (string | number)[] | { value: string | number; label: string }[];
        search?: (search: string, lang: string, isHeader?: boolean) => Promise<{ value: string; label: string }[]>;
        placeholder: string;
        code?: string;
        show?: (transit: Transit) => boolean;
      };
    };
  };
}

export const TransitDocumentFormSchema: TransitDocumentFormSchemaProps = {
  [TransitDocumentsEnum.PREVIOUS_DOCUMENT]: {
    fields: {
      type: {
        type: FormDocumentsInputType.MULTI_LANG,
        code: 'CL214',
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      complementOfInformation: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Complement Of Information',
      },
    },
  },
  [TransitDocumentsEnum.SUPPORTING_DOCUMENT]: {
    fields: {
      type: {
        type: FormDocumentsInputType.MULTI_LANG,
        code: 'CL213',
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      documentLineItemNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Document Line Item Number',
        show: (transit: Transit): boolean => transit.generalInfo?.group !== TransitGroup.ARRIVAL,
      },
      complementOfInformation: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Complement Of Information',
      },
    },
  },
  [TransitDocumentsEnum.TRANSPORT_DOCUMENT]: {
    fields: {
      type: {
        type: FormDocumentsInputType.MULTI_LANG,
        code: 'CL754',
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
    },
  },
  [TransitDocumentsEnum.ADDITIONAL_REFFERENCE]: {
    fields: {
      type: {
        type: FormDocumentsInputType.MULTI_LANG,
        code: 'CL380',
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
    },
  },
  [TransitDocumentsEnum.ADDITIONAL_INFORMATION]: {
    fields: {
      code: {
        type: FormDocumentsInputType.MULTI_LANG,
        code: 'CL239',
        placeholder: 'Code',
      },
      text: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Text',
      },
    },
  },
};
