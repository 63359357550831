/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ConsignmentItem, TransitGroup, TransitHouseConsignment } from '@e-origin/shared';

import { selectTransit } from '../../../../../../../../src/stores/transitsSlice';
import { Button, Spinner, Tab, Tabs } from '../../../../../../../components';
import ModalStyle from '../../../../../../../styles/modal';
import { GoodsItemFormInitializerReturn, goodsItemFormIntializer } from './goods-item-form-initializer';
import * as Style from './goods-item-form.style';
import { GoodsItemData, GoodsItemDocuments, GoodsItemStakeholders } from './tabs';

interface GoodsItemProps {
  goodsItem: ConsignmentItem;
  houseConsignment: TransitHouseConsignment;
  onHide: () => void;
  onSave: (goodsItem: ConsignmentItem) => void;
  disabled: boolean;
  editDisabledForConsignment: boolean;
}

export const GoodsItemForm: React.FC<GoodsItemProps> = ({
  houseConsignment,
  goodsItem,
  onSave,
  onHide,
  disabled,
  editDisabledForConsignment,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const form = useFormik<GoodsItemFormInitializerReturn>({
    initialValues: goodsItemFormIntializer(goodsItem),
    onSubmit: async (values) => {
      setIsSaving(true);

      const data = {
        ...values,
        ...values.documents,
        documents: undefined,
      };

      onSave(data);
      setIsSaving(false);
    },
  });

  const handleSubmit = async () => {
    if (!isSaving) {
      await form.submitForm();
    }
  };

  const transit = useSelector(selectTransit);
  const isArrival = transit?.generalInfo?.group === TransitGroup.ARRIVAL;

  return (
    <Style.Container>
      <Tabs style={{ margin: '0 15px 15px 15px' }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab label="Item Data">
          <GoodsItemData
            form={form}
            houseConsignment={houseConsignment}
            disabled={disabled}
            editDisabledForConsignment={editDisabledForConsignment}
          />
        </Tab>
        {!isArrival && (
          <Tab label="Stakeholders">
            <GoodsItemStakeholders form={form} houseConsignment={houseConsignment} disabled={disabled} />
          </Tab>
        )}
        <Tab label="Documents">
          <GoodsItemDocuments
            form={form}
            houseConsignment={houseConsignment}
            editDisabledForConsignment={editDisabledForConsignment}
            isArrival={isArrival}
          />
        </Tab>
      </Tabs>
      <ModalStyle.Footer>
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        {(!disabled || !editDisabledForConsignment) && (
          <Button type="button" primary onClick={handleSubmit}>
            {isSaving ? (
              <>
                <Spinner isContrast />
                Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        )}
      </ModalStyle.Footer>
    </Style.Container>
  );
};
