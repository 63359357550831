import { ControlResult } from '@e-origin/shared';
import * as Style from './control-result-details.style';

interface IControlResultProps {
  controlResult: ControlResult[];
}

export const ControlResultDetails: React.FC<IControlResultProps> = ({ controlResult }) => {
  return (
    <>
      {controlResult.map((item, index) => (
        <Style.Section key={`control-result-${index}`} topPadding={10} paddingBottom={20}>
          <Style.SectionTitle>Control result</Style.SectionTitle>
          <Style.Row>
            <div>
              <label>Date</label>
              <p>{item.date}</p>
            </div>
            <div>
              <label>Control code</label>
              <p>{item.code}</p>
            </div>
          </Style.Row>
          <Style.Row>
            <div>
              <label>Remarks</label>
              <p>{item.remarks}</p>
            </div>
          </Style.Row>
          {(item.resultsOfControl || []).map((result, resultIndex) => (
            <Style.SubSection key={`result-of-control-${index}-${resultIndex}`}>
              <Style.SubSectionTitle>Result of control</Style.SubSectionTitle>
              <Style.Row>
                <div>
                  <label>Risk area code</label>
                  <p>{result.riskAreaCode}</p>
                </div>
                <div>
                  <label>Control type</label>
                  <p>{result.controlType}</p>
                </div>
                <div>
                  <label>Control date</label>
                  <p>{result.controlDate}</p>
                </div>
                <div>
                  <label>Remarks</label>
                  <p>{result.remarks}</p>
                </div>
              </Style.Row>
              {(result.controlDetails || []).map((controlDetail, controlDetailIndex) => (
                <Style.SubSection key={`control-result-detail-${index}-${resultIndex}-${controlDetailIndex}`}>
                  <Style.SubSectionTitle>Control Detail</Style.SubSectionTitle>
                  <Style.Row>
                    <div>
                      <label>Type of discrepancies</label>
                      <p>{controlDetail.typeOfDiscrepancies}</p>
                    </div>
                    <div>
                      <label>Attribute pointer</label>
                      <p>{controlDetail.attributePointer}</p>
                    </div>
                    <div>
                      <label>Corrected value</label>
                      <p>{controlDetail.correctedValue}</p>
                    </div>
                    <div>
                      <label>Remarks</label>
                      <p>{controlDetail.remarks}</p>
                    </div>
                  </Style.Row>
                </Style.SubSection>
              ))}
            </Style.SubSection>
          ))}
        </Style.Section>
      ))}
    </>
  );
};
