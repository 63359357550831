/* eslint-disable complexity */

import { ConsignmentItem, TransitHouseConsignment } from '@e-origin/shared';

import Icons from '../../../../../../assets';
import { CustomModal } from '../../../../../../components';
import ModalStyle from '../../../../../../styles/modal';
import { GoodsItemForm } from './goods-item-form/goods-item-form';
import * as Style from './goods-item-modal.style';

export interface GoodsItemModalProps {
  goodsItem?: ConsignmentItem;
  houseConsignment: TransitHouseConsignment;
  editDisabled: boolean;
  editDisabledForConsignment: boolean;
  onHide: () => void;
  onSave: (goodsItem: ConsignmentItem) => void;
}

export const GoodsItemModal: React.FC<GoodsItemModalProps> = ({
  houseConsignment,
  goodsItem,
  editDisabled,
  editDisabledForConsignment,
  onSave,
  onHide,
}) => {
  const context = goodsItem ? 'UPDATE' : 'CREATE';
  return (
    <CustomModal width={55} show={true} onHide={onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>{context === 'CREATE' ? 'Create' : 'Update'} Goods Item</ModalStyle.Title>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <GoodsItemForm
        houseConsignment={houseConsignment}
        goodsItem={goodsItem}
        onSave={onSave}
        onHide={onHide}
        disabled={editDisabled}
        editDisabledForConsignment={editDisabledForConsignment}
      />
    </CustomModal>
  );
};
