import { ExitControlResult } from '@e-origin/shared';

import * as Style from './exit-control-result-notification.style';

interface ExitControlResultNotificationProps {
  exitControlResult: ExitControlResult;
}

export const ExitControlResultNotification: React.FC<ExitControlResultNotificationProps> = ({ exitControlResult }) => {
  return (
    <Style.Section verticalPadding={20}>
      <Style.SectionTitle>Exit Control Result</Style.SectionTitle>
      <Style.Row>
        <div>
          <label>Code</label>
          <p>{exitControlResult.code}</p>
        </div>
        <div>
          <label>Exit date</label>
          <p>{exitControlResult.exitDate}</p>
        </div>
      </Style.Row>
    </Style.Section>
  );
};
