import { useEffect, useRef, useState } from 'react';
import { TransitGroup } from '@e-origin/shared';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import * as Style from './transit-view.style';

interface ITransitViewOption {
  value: TransitGroup;
  label: string;
}

const transitViewOptions: ITransitViewOption[] = [
  { value: TransitGroup.DEPARTURE, label: 'Departure' },
  { value: TransitGroup.ARRIVAL, label: 'Arrival' },
];

interface TransitViewProps {
  value: TransitGroup;
  onChange: (value: TransitGroup) => void;
}

export const TransitViewComponent: React.FC<TransitViewProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<ITransitViewOption>(
    transitViewOptions.find((opt) => opt.value === props.value),
  );

  const ref = useRef(null);

  const onClickOutside = () => setIsOpen(false);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target) && !event.target.closest('#dropdown-container')) {
      onClickOutside();
    }
  };

  const setTransitView = (item: ITransitViewOption) => {
    if (item.value !== props.value) {
      props.onChange(item.value);
      setSelectedOption(item);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Style.Container id={'dropdown-container'} onClick={() => setIsOpen(!isOpen)}>
        <Style.TextContainer>
          <Style.Label>Transit view</Style.Label>
          <Style.DropDownContainer>{selectedOption?.label}</Style.DropDownContainer>
        </Style.TextContainer>
        <ArrowIcon style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </Style.Container>

      {isOpen && (
        <Style.DropDownList ref={ref}>
          {transitViewOptions.map((item: any) => {
            return (
              <Style.DropDownItem
                key={item.value}
                onClick={() => {
                  setTransitView(item);
                  setIsOpen(false);
                }}
              >
                <Style.Text>{item.label}</Style.Text>
              </Style.DropDownItem>
            );
          })}
        </Style.DropDownList>
      )}
    </>
  );
};
