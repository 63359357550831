import { FormikProps } from 'formik';

import { FormContainer, FormRow, FormSection } from '../../../../../styles/common';
import * as Style from './report-tab.style';

export interface IReportTabProps {
  form: FormikProps<any>;
}

export const ReportTab: React.FC<IReportTabProps> = (props) => {
  const { form } = props;

  return (
    <FormContainer>
      <FormSection topPadding={8} paddingBottom={16}>
        <FormRow style={{ alignItems: 'center', justifyContent: 'space-between', width: '750px' }}>
          <Style.PathLabel style={{ fontWeight: '600' }}>Path</Style.PathLabel>
          <Style.ValueLabel style={{ fontWeight: '600' }}>Initial value</Style.ValueLabel>
          <Style.ValueLabel style={{ fontWeight: '600' }}>New value</Style.ValueLabel>
        </FormRow>

        {form.values.trace?.map((trace) => (
          <FormRow key={trace.path} style={{ alignItems: 'center', justifyContent: 'space-between', width: '750px' }}>
            <Style.PathLabel title={trace.path}>{trace.path}</Style.PathLabel>
            <Style.ValueLabel title={trace.initialValue || 'N/A'}>{trace.initialValue || 'N/A'}</Style.ValueLabel>
            <Style.ValueLabel title={trace.newValue || 'N/A'}>{trace.newValue || 'N/A'}</Style.ValueLabel>
          </FormRow>
        ))}
      </FormSection>
    </FormContainer>
  );
};
