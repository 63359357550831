/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { TransitGroup } from '@e-origin/shared';

import { Input } from '../../../../../../../../components';
import { selectTransit } from '../../../../../../../../stores/transitsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { TransitConsignee, TransitConsignor, TransitDuplicateRow } from '../../../../../../components';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';

interface HouseStakeholdersProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseStakeholders: React.FC<HouseStakeholdersProps> = (props) => {
  const { form } = props;
  const transit = useSelector(selectTransit);
  const isArrival = transit.generalInfo?.group === TransitGroup.ARRIVAL;

  return (
    <>
      <FormContainer>
        <TransitConsignor form={form} editDisabled={props.disabled} />
        <TransitConsignee form={form} editDisabled={props.disabled} />
        {!isArrival && (
          <FormSection verticalPadding={0}>
            <FormSectionTitle>Additional supply chain actor</FormSectionTitle>
            {(transit.Consignment?.AdditionalSupplyChainActor || []).map((doc, docIdx) => (
              <FormRow key={docIdx}>
                <Input width={50} widthUnit="%" placeholder="Role" value={doc.role} compact disabled />
                <Input
                  width={50}
                  widthUnit="%"
                  placeholder="Identification number"
                  value={doc.identificationNumber}
                  compact
                  disabled
                />
              </FormRow>
            ))}

            <TransitDuplicateRow
              addButtonLabel="Add new additional supply chain actor"
              form={form}
              inputs={[
                {
                  type: 'MultiLang',
                  label: 'Role',
                  name: 'role',
                  code: 'CL704',
                },
                {
                  type: 'Input',
                  label: 'Identification number',
                  name: 'identificationNumber',
                },
              ]}
              arrayPath="AdditionalSupplyChainActor"
              compact
              disabled={props.disabled}
            />
          </FormSection>
        )}
      </FormContainer>
    </>
  );
};
