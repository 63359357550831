/* eslint-disable max-lines */
/* eslint-disable max-statements */
import Cookies from 'js-cookie';
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Transit, TransitGroup, TransitStatus } from '@e-origin/shared';

import { TableActionsIcons } from '../../../assets';
import customsSyncIcon from '../../../assets/icons/customs-sync-icon.svg';
import { CustomTableActionsComponent } from '../../../components';
import {
  customsSync,
  fetchTransits,
  generateNCTSResponse,
  generateTransitFollowLetter,
  generateTransitReport,
  sendOne,
} from '../../../stores/transitsSlice';
import { STORAGE_KEYS } from '../../../utils';
import { TransitModalFactoryEnum } from '../modal/transit-modal-factory.component';
import TransitsStatusIndicator from '../transit-status-indicator/transit-status-indicator.component';

export const useTransitsTableColumns = ({ openModal }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const actions = (row: Transit) => {
    const finalizeAllowed =
      row.generalInfo?.group === 'A' &&
      row.TransitOperation.simplifiedProcedure === '1' &&
      ![TransitStatus.NOT_SENT, TransitStatus.REJECTED].includes(row.generalInfo?.status);

    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/transits-details/${row._id}`);
        },
        preventDefaultClick: true,
      },
      {
        if: [TransitStatus.NOT_SENT, TransitStatus.REJECTED].includes(row.generalInfo?.status),
        icon: TableActionsIcons.DELETE,
        text: 'Delete transit',
        onClick: () => openModal(row, TransitModalFactoryEnum.DELETE),
        disableAfterClick: false,
        preventDefaultClick: true,
      },
      {
        if:
          [TransitStatus.NOT_SENT, TransitStatus.REJECTED, TransitStatus.UNLOAD_PERMISSION].includes(
            row.generalInfo?.status,
          ) && !finalizeAllowed,
        icon: TableActionsIcons.SEND,
        text: 'Send',
        onClick: async () => {
          await sendOne(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
        preventDefaultClick: true,
      },
      {
        if: finalizeAllowed,
        icon: TableActionsIcons.FINALIZE,
        text: 'Finalize',
        onClick: () => openModal(row, TransitModalFactoryEnum.FINALIZE),
        preventDefaultClick: true,
      },
      {
        if: [TransitStatus.SENT, TransitStatus.ACCEPTED, TransitStatus.RELEASED, TransitStatus.REMARKS_SENT].includes(
          row.generalInfo?.status,
        ),
        icon: customsSyncIcon,
        text: 'Customs sync',
        onClick: async () => {
          await customsSync(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
        preventDefaultClick: true,
      },
    ];
  };

  const secondaryActions = (row: Transit) => {
    return [
      {
        icon: TableActionsIcons.REPORT,
        text: 'Generate customs report',
        onClick: () => {
          dispatch(generateTransitReport(row._id));
        },
        preventDefaultClick: true,
      },
      {
        icon: TableActionsIcons.REPORT,
        text: 'Generate follow letter',
        onClick: () => {
          dispatch(generateTransitFollowLetter(row._id));
        },
        preventDefaultClick: true,
      },
      {
        if: row.generalInfo?.group !== 'A',
        icon: TableActionsIcons.COPY,
        text: 'Duplicate transit',
        onClick: () => openModal(row, TransitModalFactoryEnum.DUPLICATE),
        preventDefaultClick: true,
      },
    ];
  };

  const handleTableAction = async (actionType: string, row: Transit) => {
    const [, responseType] = actionType.match(/generateNCTSResponse-(.*)/);
    await generateNCTSResponse(row._id, responseType);
    dispatch(fetchTransits({ persistPagination: true }));
  };

  const buildColumns = (): IDataTableColumn<Transit>[] => [
    {
      name: 'Status',
      width: '75px',
      selector: 'generalInfo.status',
      sortable: true,
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          <TransitsStatusIndicator
            status={row.generalInfo.status}
            isArrival={row?.generalInfo?.group === TransitGroup.ARRIVAL}
          />
        </div>
      ),
    },
    {
      name: 'Transit ID',
      width: '100px',
      selector: 'counter',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      minWidth: '150px',
      sortable: true,
    },
    {
      name: 'Decl. type',
      width: '120px',
      selector: 'TransitOperation.declarationType',
    },
    {
      name: 'LRN',
      selector: 'TransitOperation.LRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'MRN',
      selector: 'TransitOperation.MRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Holder of transit proc.',
      cell: (row) => row.HolderOfTheTransitProcedure?.ContactPerson?.name,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of Departure',
      cell: (row) => row.CustomsOfficeOfDeparture?.referenceNumber,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of Destination',
      cell: (row) => row.CustomsOfficeOfDestinationDeclared?.referenceNumber,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Amount to be covered',
      selector: 'generalInfo.totalAmountGuaranteeToBeCovered',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Packages',
      selector: 'Consignment.totalNumberOfPackages',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Gross Mass',
      selector: 'Consignment.grossMass',
      sortable: true,
    },
    {
      name: 'Seals',
      selector: 'Consignment.totalNumberOfSeals',
      sortable: true,
    },
    {
      name: 'Deadline',
      selector: (row) => row.CustomsOfficeOfTransitDeclared?.[0]?.arrivalDateAndTimeEstimated || 'N/A',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: (row) => row.customer?.name,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Template',
      selector: (row) => row.template?.name,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Last update',
      selector: 'updatedAt',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Actions',
      minWidth: '180px',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={actions(row)}
          secondaryActions={secondaryActions(row)}
          onClick={(param?: string) => handleTableAction(param, row)}
          disableAfterClick={true}
          transitView={Cookies.get(STORAGE_KEYS.COOKIES.TRANSIT_VIEW) as any}
          showDebugMenu={true}
        />
      ),
    },
  ];

  const columns = useRef<IDataTableColumn<Transit>[]>([]);
  useEffect(() => {
    columns.current = buildColumns();
  }, []);

  return columns;
};
