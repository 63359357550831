import styled from 'styled-components';

export const TitleContainer = styled.div``;

export const TransitName = styled.h4`
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
`;
