/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { Transit, TransitDocumentsEnum, TransitStatus } from '@e-origin/shared';

import { TransitDetailsFormProps } from '../../transit-details.component';
import { TransitDocumentsComponent } from '../../transit-documents-component';

interface TransitDetailsDocumentsProps {
  form: FormikProps<TransitDetailsFormProps>;
  transit: Transit;
  editDisabled: boolean;
}

export interface DocumentItemProps {
  type: TransitDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  dataSource: any;
  dataSourceIndex: number;
}

export const TransitDetailsDocuments: React.FC<TransitDetailsDocumentsProps> = ({ transit, form, editDisabled }) => {
  const handleDocumentDelete = (documentType: any, documentIndex: number) => {
    form.setFieldValue(`documents.${documentType}`, [
      ...(form.values.documents[documentType] || []).filter((_, idx) => idx !== documentIndex),
    ]);
  };

  const handleSavingDocument = (savedDocument) => {
    if (savedDocument.docIndex >= 0) {
      const updatedDocuments = form.values.documents[savedDocument.type].map((doc, idx) => {
        if (idx === savedDocument.docIndex) {
          return savedDocument.data;
        }
        return doc;
      });
      form.setFieldValue(`documents.${savedDocument.type}`, updatedDocuments);
    } else {
      form.setFieldValue(`documents.${savedDocument.type}`, [
        ...(form.values.documents[savedDocument.type] || []),
        savedDocument.data,
      ]);
    }
  };

  return (
    <TransitDocumentsComponent
      documents={form.values.documents}
      onDelete={handleDocumentDelete}
      onSave={handleSavingDocument}
      disabled={transit.generalInfo.status !== TransitStatus.UNLOAD_PERMISSION && editDisabled}
    />
  );
};
