/* eslint-disable complexity */
import { Transit } from '@e-origin/shared';
import { omit } from 'lodash';

export const buildTransitFormInitialValues = (transit: Transit) => {
  return omit(
    {
      ...transit,
      Authorisation: transit.Authorisation.length ? transit.Authorisation : [{}],
      CustomsOfficeOfTransitDeclared: transit.CustomsOfficeOfTransitDeclared.length
        ? transit.CustomsOfficeOfTransitDeclared
        : [{}],
      Consignment: {
        ...transit.Consignment,
        ActiveBorderTransportMeans: transit.Consignment.ActiveBorderTransportMeans.length
          ? transit.Consignment.ActiveBorderTransportMeans
          : [{}],
        AdditionalSupplyChainActor: transit.Consignment.AdditionalSupplyChainActor.length
          ? transit.Consignment.AdditionalSupplyChainActor
          : [{}],
        DepartureTransportMeans: transit.Consignment.DepartureTransportMeans.length
          ? transit.Consignment.DepartureTransportMeans
          : [{}],
        TransportEquipment: transit.Consignment.TransportEquipment.length
          ? transit.Consignment.TransportEquipment
          : [{}],
      },
      documents: {
        PreviousDocument: transit.Consignment?.PreviousDocument?.length ? transit.Consignment.PreviousDocument : [],
        SupportingDocument: transit.Consignment?.SupportingDocument?.length
          ? transit.Consignment.SupportingDocument
          : [],
        TransportDocument: transit.Consignment?.TransportDocument?.length ? transit.Consignment.TransportDocument : [],
        AdditionalReference: transit.Consignment?.AdditionalReference?.length
          ? transit.Consignment.AdditionalReference
          : [],
        AdditionalInformation: transit.Consignment?.AdditionalInformation?.length
          ? transit.Consignment.AdditionalInformation
          : [],
      },
      refreshCustomerData: false,
    },
    ['customsState'],
  );
};
