/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';

import { Transit } from '@e-origin/shared';

import DuplicateTransitModal from './duplicate-transit-modal/duplicate-transit-modal.component';
import { FinalizeTransitModal } from './finalize-transit-modal/finalize-transit-modal';
import { TransitDeleteModal } from './transit-delete-modal/transit-delete-modal.component';

export enum TransitModalFactoryEnum {
  DELETE = 'DELETE',
  FINALIZE = 'FINALIZE',
  DUPLICATE = 'DUPLICATE',
}

export interface TransitModalFactoryProps {
  show: boolean;
  selectedData: Transit;
  modal: TransitModalFactoryEnum;
  onHide: () => void;
}

export const TransitModalFactory: React.FC<TransitModalFactoryProps> = (props) => {
  const data = useRef<Transit>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.show !== undefined) {
      setOpen(props.show);
      data.current = props.selectedData;
    }
  }, [props.show]);

  const onHide = () => {
    setOpen(false);
    data.current = null;
    props.onHide();
  };

  return (
    <>
      {open && (
        <>
          {props.modal === TransitModalFactoryEnum.DELETE && (
            <TransitDeleteModal transitId={data.current._id} open={open} onHide={onHide} />
          )}
          {props.modal === TransitModalFactoryEnum.FINALIZE && (
            <FinalizeTransitModal transit={data.current} onHide={onHide} />
          )}
          {props.modal === TransitModalFactoryEnum.DUPLICATE && (
            <DuplicateTransitModal transit={data.current} onHide={onHide} />
          )}
        </>
      )}
    </>
  );
};
