import _ from 'lodash';
/* eslint-disable complexity */
import { useState } from 'react';
import { useDispatch } from 'react-redux';

/* eslint-disable max-statements */
import { Transit, TransitStatus } from '@e-origin/shared';

import { Button, Dropdown } from '../../../../components';
import { releaseTransit } from '../../../../stores/transitsSlice';
import { transitExtractStatus } from '../../transit-details-extract-status';
import { ControlNotification } from './control-notification/control-notification.component';
import { ControlResultDetails } from './control-result/control-result-details.component';
import { Discrepancies } from './discrepancies/discrepancies.component';
import { TransitInvalidation } from './invalidation/transit-invalidation.component';
import { NonArrivalInfo } from './non-arrival-info/non-arrival-info.component';
import {
  BusinessRejectionRow,
  Container,
  ErrorSection,
  FunctionalErrorItem,
  FunctionalErrorRow,
  FunctionalErrorTitle,
  Header,
  RejectionInfo,
  RequestReleaseContainer,
  RequestReleaseForm,
} from './transit-details-customs.style';

interface ITransitDetailsCustoms {
  transit: Transit;
}

export const TransitDetailsCustoms: React.FC<ITransitDetailsCustoms> = ({ transit: transitObj }) => {
  const transit = _.cloneDeep(transitObj);

  const dispatch = useDispatch();
  const [approveRevisions, setApproveRevisions] = useState<boolean | null>(null);

  const handleSendReleaseRequest = () => {
    dispatch(releaseTransit(transit, approveRevisions));
  };

  const updateApproveRevisions = (selectedOption) => {
    setApproveRevisions(selectedOption.value);
  };

  if (!transit?.customsState) {
    return <div>Not sent yet</div>;
  }

  return (
    <Container>
      <Header>
        <div>
          <label>Status</label>
          <p>{transitExtractStatus(transit.generalInfo.status)}</p>
        </div>
        <div>
          <label>Correlation ID</label>
          <p>{transit.customsState?.correlationId || 'N/A'}</p>
        </div>
        {transit.TransitOperation?.MRN && (
          <div>
            <label>Movement reference number</label>
            <p>{transit.TransitOperation?.MRN}</p>
          </div>
        )}
        {transit.customsState?.dateOfAcceptance && (
          <div>
            <label>Date of acceptance</label>
            <p>{transit.customsState?.dateOfAcceptance}</p>
          </div>
        )}
        {transit.customsState?.noRelease?.rejectionDate && (
          <div>
            <label>Rejection date</label>
            <p>{transit.customsState?.noRelease?.rejectionDate}</p>
          </div>
        )}
      </Header>
      {/** control info */}
      {transit.generalInfo.status === TransitStatus.IN_CONTROL && transit.customsState.inControl && (
        <ControlNotification inControl={transit.customsState.inControl} />
      )}
      {/** /control info */}

      {(transit.generalInfo.status === TransitStatus.REJECTED ||
        transit.generalInfo.status === TransitStatus.UNLOAD_PERMISSION) && (
        <RejectionInfo>
          {transit.customsState?.customsDeclarationRejection && (
            <ErrorSection>
              <BusinessRejectionRow>
                <div>
                  <label>Business rejection type</label>
                  <p>{transit.customsState.customsDeclarationRejection.businessRejectionType}</p>
                </div>
              </BusinessRejectionRow>
              <BusinessRejectionRow>
                <div>
                  <label>Rejection date</label>
                  <p>{transit.customsState.customsDeclarationRejection.rejectionDate}</p>
                </div>
                {transit.customsState.customsDeclarationRejection.rejectionCode && (
                  <div>
                    <label>Rejection code</label>
                    <p>{transit.customsState.customsDeclarationRejection.rejectionCode}</p>
                  </div>
                )}
                {transit.customsState.customsDeclarationRejection.rejectionReason && (
                  <div>
                    <label>Rejection reason</label>
                    <p>{transit.customsState.customsDeclarationRejection.rejectionReason}</p>
                  </div>
                )}
              </BusinessRejectionRow>
            </ErrorSection>
          )}
          {!!transit.customsState?.functionalError?.length && (
            <ErrorSection>
              {transit.customsState?.functionalError &&
                transit.customsState?.functionalError.map((item, index) => (
                  <FunctionalErrorRow key={index}>
                    <FunctionalErrorTitle>Functional error</FunctionalErrorTitle>
                    <FunctionalErrorItem>
                      {Number(item.sequenceNumber) > 0 && (
                        <div>
                          <label>Sequence number</label>
                          <p>{item.sequenceNumber}</p>
                        </div>
                      )}
                      <div>
                        <label>Error code</label>
                        <p>{item.errorCode}</p>
                      </div>
                    </FunctionalErrorItem>
                    <FunctionalErrorItem>
                      <div>
                        <label>Error pointer</label>
                        <p>{item.errorPointer}</p>
                      </div>
                    </FunctionalErrorItem>
                    <FunctionalErrorItem>
                      <div>
                        <label>Error reason</label>
                        <p>{item.errorReason}</p>
                      </div>
                    </FunctionalErrorItem>
                    {item.remarks && item.remarks.length && (
                      <FunctionalErrorItem>
                        <div>
                          <label>Remarks</label>
                          <p>{item.remarks}</p>
                        </div>
                      </FunctionalErrorItem>
                    )}
                    {item.originalAttributeValue && item.originalAttributeValue.length && (
                      <FunctionalErrorItem>
                        <div>
                          <label>Original Attribute</label>
                          <p>{item.originalAttributeValue}</p>
                        </div>
                      </FunctionalErrorItem>
                    )}
                  </FunctionalErrorRow>
                ))}
            </ErrorSection>
          )}
        </RejectionInfo>
      )}

      <TransitInvalidation transit={transit} />

      {/** request release */}
      {transit.generalInfo?.status === TransitStatus.IN_CONTROL && (
        <RequestReleaseContainer>
          <RequestReleaseForm>
            <label>Approve revisions</label>
            <Dropdown
              menuOverflow={true}
              placeholder="Approve revisions"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              onChange={updateApproveRevisions}
              maxMenuHeight={200}
              compact
              width={47}
              widthUnit="%"
            />
            <div>
              <Button type="button" primary disabled={approveRevisions === null} onClick={handleSendReleaseRequest}>
                Request Release
              </Button>
            </div>
          </RequestReleaseForm>
        </RequestReleaseContainer>
      )}
      {/** /request release */}
      {/** requested non-arrival info */}
      {transit.generalInfo?.status === TransitStatus.REQUESTED_NON_ARRIVED && <NonArrivalInfo transit={transit} />}
      {/** / requested non-arrival info */}
      {transit.generalInfo?.status === TransitStatus.NOT_RELEASED && transit.customsState?.controlResult && (
        <ControlResultDetails controlResult={transit.customsState?.controlResult} />
      )}
      {!!transit.customsState?.discrepancies?.length && (
        <Discrepancies discrepancies={transit.customsState?.discrepancies} />
      )}
    </Container>
  );
};
