/* eslint-disable complexity */
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import * as Picker from 'react-datepicker';

import { EORIGIN_DATE_FORMAT } from '@e-origin/shared';

import { Input } from '../';
import Icons from '../../assets';
import * as Style from './date-picker.style';

interface DatePickerProps {
  name?: string;
  placeholder: string;
  value: Date;
  format?: string;
  onChange(value: Date): void;
  width?: number;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  disabled?: boolean;
  hideClearButton?: boolean;
  pickerProps?: Partial<Picker.ReactDatePickerProps>;
}

export const DatePicker: React.FC<DatePickerProps> = (props) => {
  const [date, setDate] = useState(props.value);
  const datePickerRef = useRef<any>(null);

  const onChangeDate = (value: Date) => {
    setDate(value);
    props.onChange(value);
  };

  useEffect(() => {
    setDate(props.value);
  }, [props.value]);

  return (
    <Style.Container width={props.width || 100} widthUnit={props.widthUnit}>
      <Input
        defaultBehaviour
        name={props.name}
        value={
          date ? moment(date, props.format || EORIGIN_DATE_FORMAT).format(props.format || EORIGIN_DATE_FORMAT) : ''
        }
        placeholder={props.placeholder}
        onClick={() => datePickerRef.current.setOpen(true)}
        width={100}
        widthUnit="%"
        autoComplete="off"
        disabled={props.disabled}
      />
      {!props.disabled && !props.hideClearButton && (
        <Style.ClearButton
          onClick={(e) => {
            e.preventDefault();
            onChangeDate(null);
          }}
        >
          <img src={Icons.CloseIcon} />
        </Style.ClearButton>
      )}
      <Style.HiddenContainer>
        <Picker.default
          ref={datePickerRef}
          selected={props.value}
          onSelect={onChangeDate}
          onChange={onChangeDate}
          {...props.pickerProps}
        />
      </Style.HiddenContainer>
    </Style.Container>
  );
};
