/* eslint-disable prefer-template */
/* eslint-disable complexity */

import { useFormik } from 'formik';
import { merge } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Transit, TransitStatus } from '@e-origin/shared';

import Icons from '../../../../assets';
import { Button, CustomModal, Spinner, Tab, Tabs } from '../../../../components';
import { fetchTransits, sendOne, updateTransit } from '../../../../stores/transitsSlice';
import ModalStyle from '../../../../styles/modal';
import * as Style from './finalize-transit-modal.style';
import { ReportTab } from './report-tab/report-tab';
import { SummaryTab } from './summary-tab/summary-tab';

interface FinalizeTransitModalProps {
  transit: Transit;
  onHide(): void;
}

export const FinalizeTransitModal: React.FC<FinalizeTransitModalProps> = (props) => {
  const dispatch = useDispatch();
  const [transit, setTransit] = useState(null);
  const [editDisabled, setEditDisabled] = useState(null);
  const { onHide } = props;

  const computeUnloadingRemark = (t: Transit) => ({
    unloadingCompletion: t.UnloadingRemark?.unloadingCompletion || '1',
    unloadingDate: `${moment(t.UnloadingRemark?.unloadingDate || new Date()).format('YYYY-MM-DD')}T00:00:00`,
    stateOfSeals: t.UnloadingRemark?.stateOfSeals,
    unloadingRemark: t.UnloadingRemark?.unloadingRemark,
    conform: Number(t.UnloadingRemark?.stateOfSeals !== '0' && !t.UnloadingRemark?.unloadingRemark?.length).toString(),
  });

  const form = useFormik({
    initialValues: {
      UnloadingRemark: {},
      trace: [],
    },
    onSubmit: async (values) => {
      dispatch(updateTransit(merge(transit, values), { refreshCustomerData: false }));
      setTimeout(async () => {
        await sendOne(transit._id);
        dispatch(fetchTransits());
        onHide();
      }, 1000);
    },
    enableReinitialize: false,
  });

  useEffect(() => {
    if (props.transit) {
      const UnloadingRemark = computeUnloadingRemark(props.transit);
      setTransit({ ...props.transit, UnloadingRemark });
      form.setFieldValue('UnloadingRemark', UnloadingRemark);
      form.setFieldValue('trace', props.transit.trace);
      setEditDisabled(props.transit.generalInfo.status !== TransitStatus.UNLOAD_PERMISSION);
    }
  }, [props.transit]);

  return (
    <CustomModal width={55} show={true} onHide={onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>Finalize transit</ModalStyle.Title>
          <Style.TransitName>{transit?.name}</Style.TransitName>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      {!transit ? (
        <Style.SpinnerWrapper>
          <Spinner />
        </Style.SpinnerWrapper>
      ) : (
        <Tabs style={{ margin: '0 15px 15px 15px' }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
          <Tab label="Summary">
            <SummaryTab form={form} editDisabled={editDisabled} />
          </Tab>
          <Tab label="Report">
            <ReportTab form={form} />
          </Tab>
        </Tabs>
      )}
      <ModalStyle.Footer>
        <Button type="button" onClick={onHide}>
          Cancel
        </Button>
        {!editDisabled && (
          <Button type="button" primary onClick={form.handleSubmit}>
            Send
          </Button>
        )}
      </ModalStyle.Footer>
    </CustomModal>
  );
};
