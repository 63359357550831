import { MultilingualCodeSystems } from '@e-origin/shared';
import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../stores/declarantSlice';
import { retrieveCodes } from '../../stores/settingsSlice';
import { Autocomplete } from '../autocomplete/autocomplete.component';

interface MultiLangCodesProps {
  width?: number;
  widthUnit?: 'em' | 'px' | '%' | 'rem';
  placeholder: string;
  name: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  code: string | string[];
  system: MultilingualCodeSystems;
  value: string;
  disabled?: boolean;
  compact?: boolean;
}

export const MultiLangCodes: React.FC<MultiLangCodesProps> = ({
  name,
  value,
  code,
  placeholder,
  onChange,
  onBlur,
  width = 100,
  widthUnit = '%',
  disabled = false,
  compact = false,
  system,
}) => {
  const declarant = useSelector(selectDeclarant);

  return (
    <Autocomplete
      name={name}
      width={width}
      widthUnit={widthUnit}
      placeholder={placeholder}
      fetchOptions={(search) => retrieveCodes(code, search, declarant.language, system)}
      onChangeNative={onChange}
      onBlur={onBlur}
      value={{
        value,
        label: value,
      }}
      disabled={disabled}
      compact={compact}
    />
  );
};
