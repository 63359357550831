import styled from 'styled-components';

export const PathLabel = styled.label`
  margin-right: 16px;
  width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ValueLabel = styled.label`
  margin-right: 16px;
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
`;
