/* eslint-disable prefer-template */
import { FormikProps } from 'formik';
import moment from 'moment';
import { useRef } from 'react';

import { DatePicker, Dropdown, Input } from '../../../../../components';
import { IDropDownOption } from '../../../../../interfaces/dropdown-option.interface';
import { FormContainer, FormRow, FormSection } from '../../../../../styles/common';

interface SummaryTabInputs {
  field: string;
  label: string;
  disabled: boolean;
  type: 'input' | 'datepicker' | 'dropdown';
  options?: IDropDownOption[];
}

interface ISummaryTabProps {
  form: FormikProps<any>;
  editDisabled: boolean;
}

export const SummaryTab: React.FC<ISummaryTabProps> = (props) => {
  const { form, editDisabled } = props;

  const inputs = useRef<SummaryTabInputs[]>([
    {
      field: 'conform',
      label: 'Conform',
      disabled: true,
      type: 'dropdown',
      options: [
        {
          value: '0',
          label: 'No',
        },
        {
          value: '1',
          label: 'Yes',
        },
      ],
    },
    {
      field: 'unloadingCompletion',
      label: 'Unloading completion',
      disabled: editDisabled,
      type: 'dropdown',
      options: [
        {
          value: '0',
          label: 'No',
        },
        {
          value: '1',
          label: 'Yes',
        },
      ],
    },
    {
      field: 'unloadingDate',
      label: 'Unloading date',
      disabled: editDisabled,
      type: 'datepicker',
    },
    {
      field: 'stateOfSeals',
      label: 'State of seals',
      disabled: editDisabled,
      type: 'dropdown',
      options: [
        {
          value: '',
          label: '',
        },
        {
          value: '0',
          label: '0',
        },
        {
          value: '1',
          label: '1',
        },
      ],
    },
    {
      field: 'unloadingRemark',
      label: 'Unloading remark',
      disabled: editDisabled,
      type: 'input',
    },
  ]);

  const handleChange = (e) => {
    form.setFieldValue(e.target.name, e.target.value);
    if (e.target.name === 'UnloadingRemark.stateOfSeals') {
      form.setFieldValue(
        'UnloadingRemark.conform',
        '' + Number(e.target.value !== '0' && !form.getFieldProps('UnloadingRemark.unloadingRemark').value?.length),
      );
    }
    if (e.target.name === 'UnloadingRemark.unloadingRemark') {
      form.setFieldValue(
        'UnloadingRemark.conform',
        '' + Number(form.getFieldProps('UnloadingRemark.stateOfSeals').value !== '0' && !e.target.value?.length),
      );
    }
  };

  return (
    <FormContainer>
      <FormSection topPadding={8} paddingBottom={16}>
        {inputs.current.map((input) => (
          <FormRow
            key={`RowUnloadingRemark.${input.field}`}
            style={{ alignItems: 'center', justifyContent: 'space-between', width: '500px' }}
          >
            <label style={{ marginRight: '16px' }}>{input.label}</label>
            {input.type === 'input' && (
              <Input
                name={`UnloadingRemark.${input.field}`}
                onChange={handleChange}
                value={form.getFieldProps(`UnloadingRemark.${input.field}`).value}
                width={280}
                widthUnit="px"
                disabled={input.disabled}
              />
            )}
            {input.type === 'datepicker' && (
              <DatePicker
                name={`UnloadingRemark.${input.field}`}
                placeholder=""
                value={new Date(form.getFieldProps(`UnloadingRemark.${input.field}`).value)}
                onChange={(value) =>
                  handleChange({
                    target: {
                      name: `UnloadingRemark.${input.field}`,
                      value: `${moment(value).format('YYYY-MM-DD')}T00:00:00`,
                    },
                  })
                }
                width={280}
                widthUnit="px"
                disabled={input.disabled}
                hideClearButton={true}
              />
            )}
            {input.type === 'dropdown' && (
              <Dropdown
                placeholder=""
                options={input.options}
                onChange={(selectedOption) =>
                  handleChange({
                    target: {
                      name: `UnloadingRemark.${input.field}`,
                      value: selectedOption?.value,
                    },
                  })
                }
                value={(input.options as any).find(
                  (item) => item.value === form.getFieldProps(`UnloadingRemark.${input.field}`).value,
                )}
                width={280}
                widthUnit="px"
                maxMenuHeight={150}
                disabled={input.disabled}
              />
            )}
          </FormRow>
        ))}
      </FormSection>
    </FormContainer>
  );
};
