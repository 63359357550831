/* eslint-disable no-restricted-syntax */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { cloneDeep, mergeWith } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Transit, TransitDocument, TransitGroup, TransitStatus } from '@e-origin/shared';

import { Button, NotificationModal, Spinner, Tab, Tabs } from '../../components';
import {
  clearTransits,
  fetchTransit,
  selectTransit,
  selectTransitIsLoading,
  updateTransit,
} from '../../stores/transitsSlice';
import { LeavePageModal } from '../leave-page-modal/leave-page-modal.component';
import TransitDetailsHeader from './header/transit-details-header.component';
import { buildTransitFormInitialValues } from './transit-form-initializer';
import { TransitDetailsCustoms } from './tabs/transit-details-customs/transit-details-customs.component';
import { TransitDetailsDocuments } from './tabs/transit-details-documents/transit-details-documents.component';
import { TransitGeneralInfo } from './tabs/transit-details-general-info.component';
import { TransitDetailsHouse } from './tabs/transit-details-house';
import { TransitDetailsIncidentsCustoms } from './tabs/transit-details-incident-customs/transit-details-incident-customs.component';
import { TransitStakeholders } from './tabs/transit-details-stakeholders.component';
import * as Style from './transit-details.style';

export interface TransitDetailsFormProps extends Transit {
  documents: {
    PreviousDocument: TransitDocument[];
    SupportingDocument: TransitDocument[];
    TransportDocument: TransitDocument[];
    AdditionalReference: TransitDocument[];
    AdditionalInformation: TransitDocument[];
  };
  refreshCustomerData: boolean;
}

export const TransitDetails: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const [editDisabledForConsignment, setEditDisabledForConsignment] = useState(false);
  const transit = useSelector(selectTransit);
  const isLoading = useSelector(selectTransitIsLoading);
  const isArrival = transit?.generalInfo?.group === TransitGroup.ARRIVAL;
  const [showOverwriteModal, setShowOverwriteModal] = useState(false);

  const submittedData = useRef<TransitDetailsFormProps>(null);

  const saveData = async () => {
    const data = cloneDeep(submittedData.current);
    const { refreshCustomerData } = data;

    data.Consignment = {
      ...data.Consignment,
      ...data.documents,
    };

    delete data.documents;
    delete data.refreshCustomerData;

    dispatch(
      updateTransit(
        mergeWith(cloneDeep(transit), data, (objValue, srcValue) => (Array.isArray(srcValue) ? srcValue : undefined)),
        { refreshCustomerData },
      ),
    );
  };

  const isDataOverwritten = (data: Transit) => {
    if (
      data.Consignment?.HouseConsignment?.some((house) => {
        return (
          (data.Consignment?.countryOfDispatch && house.countryOfDispatch) ||
          (data.Consignment?.referenceNumberUCR && house.referenceNumberUCR) ||
          (data.Consignment?.Consignor?.name && (house.Consignor?.name || house.Consignor?.identificationNumber)) ||
          (data.Consignment?.Consignor?.identificationNumber &&
            (house.Consignor?.name || house.Consignor?.identificationNumber)) ||
          (data.Consignment?.Consignee?.name && (house.Consignee?.name || house.Consignee?.identificationNumber)) ||
          (data.Consignment?.Consignee?.identificationNumber &&
            (house.Consignee?.name || house.Consignee?.identificationNumber)) ||
          house.ConsignmentItem?.some(
            (item) =>
              (data.TransitOperation?.declarationType && item.declarationType) ||
              ((data.Consignment?.countryOfDispatch || house.countryOfDispatch) && item.countryOfDispatch) ||
              (data.Consignment?.countryOfDestination && item.countryOfDestination) ||
              ((data.Consignment?.referenceNumberUCR || house.referenceNumberUCR) && item.referenceNumberUCR),
          )
        );
      })
    ) {
      return true;
    }

    return false;
  };

  const form = useFormik<TransitDetailsFormProps>({
    initialValues: transit ? buildTransitFormInitialValues(transit) : undefined,
    onSubmit: async (values) => {
      submittedData.current = values;

      if (isDataOverwritten(values)) {
        setShowOverwriteModal(true);
        return;
      }

      await saveData();
      form.setTouched({});
    },
    enableReinitialize: true,
  });

  const hideOverwriteModal = () => setShowOverwriteModal(false);
  const handleOverwriteConfirm = () => saveData();

  useEffect(() => {
    dispatch(fetchTransit(id));
    return () => {
      dispatch(clearTransits());
    };
  }, []);

  useEffect(() => {
    if (transit) {
      const isEditable = [TransitStatus.REJECTED, TransitStatus.NOT_SENT].includes(transit.generalInfo?.status);
      setEditDisabledForConsignment(
        isArrival ? transit.generalInfo.status !== TransitStatus.UNLOAD_PERMISSION : !isEditable,
      );
      setEditDisabled(!isEditable);
    }
  }, [transit]);

  if (!transit) {
    return null;
  }

  return (
    <>
      {(!editDisabled || !editDisabledForConsignment) && <LeavePageModal hasUnsavedChanges={form.dirty} />}
      <Style.Container>
        {transit && <TransitDetailsHeader form={form} transitDetails={transit} editDisabled={editDisabled} />}
        {form.values && (
          <>
            <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
              <Tab label="General Info">
                <TransitGeneralInfo
                  form={form}
                  editDisabled={editDisabled}
                  editDisabledForConsignment={editDisabledForConsignment}
                />
              </Tab>
              <Tab label="Stakeholders">
                <TransitStakeholders form={form} editDisabled={editDisabled} />
              </Tab>
              <Tab label="Documents">
                <TransitDetailsDocuments transit={transit} form={form} editDisabled={editDisabled} />
              </Tab>
              <Tab label="House Consignments">
                <TransitDetailsHouse
                  form={form}
                  editDisabled={editDisabled}
                  editDisabledForConsignment={editDisabledForConsignment}
                />
              </Tab>
              {isArrival && (
                <Tab label="Incidents">
                  <TransitDetailsIncidentsCustoms />
                </Tab>
              )}
              <Tab label="Customs State">
                <TransitDetailsCustoms transit={transit} />
              </Tab>
            </Tabs>
            {(!editDisabled || !editDisabledForConsignment) && (
              <Style.Footer>
                <Button
                  type="submit"
                  onClick={async () => form.submitForm()}
                  primary
                  key="save-modal-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner isContrast />
                      Saving
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Style.Footer>
            )}
          </>
        )}
      </Style.Container>
      {showOverwriteModal && (
        <NotificationModal
          show={true}
          title="Confirm save"
          onHide={hideOverwriteModal}
          onConfirm={handleOverwriteConfirm}
        >
          <div>
            There are some headers values that had been defined. Saving will overwrite the lower level values. Are you
            sure you want to continue?
          </div>
        </NotificationModal>
      )}
    </>
  );
};
