/* eslint-disable max-classes-per-file */
import { prop, Ref } from '@typegoose/typegoose';

import { TemplateType } from '../enums';
import { BaseModel } from './base.model';
import { Declarant } from './declarant';
import { DomainCollection } from './domain.collection';
import { TransactionType } from './transactionType';

export class ShipmentsMapping {
  @prop({ required: true })
  startRowIndex: number;

  @prop({ type: [String], required: true })
  mappingFields: string[];
}

export class PackagingList {
  @prop({ required: true })
  startRowIndex: number;

  @prop({ required: true })
  shippingMarksColumn: string;

  @prop({ required: true })
  descriptionOfGoodsColumn: string;

  @prop({ required: true })
  quantityCountColumn: string;

  @prop({ required: true })
  packagesCountColumn: string;

  @prop({ required: true })
  grossWeightColumn: string;

  @prop()
  netWeightColumn: string;

  @prop()
  hsCodeColumn: string;
}

export class SendReport {
  @prop()
  referenceValue: string;

  @prop()
  webValueChecked: string;

  @prop()
  suggestedUnitPrice: string;

  @prop()
  suggestedValue: string;

  @prop()
  resonableValueRate: string;

  @prop()
  netMargin: string;

  @prop()
  suggestedTaxRate: string;

  @prop()
  dutyEuro: string;

  @prop()
  dutyImpactEuro: string;

  @prop()
  suggestedDutyEuro: string;

  @prop()
  calculatedImpact: string;

  @prop()
  suggestedHsCode: string;

  @prop()
  suggestedDescription: string;

  @prop()
  LRN: string;

  @prop()
  goodsItem: string;

  @prop()
  annotationNote: string;
}

export class HighValue {
  @prop({ type: () => [String] })
  stakeholdersMapping?: string[];

  @prop({ _id: false, type: () => ShipmentsMapping })
  shipmentsMapping?: ShipmentsMapping;

  @prop({ _id: false, type: () => PackagingList })
  packagingList?: PackagingList;

  @prop({ type: [String] })
  othersMappings: string[];

  @prop({ _id: false, type: SendReport })
  sendReport: SendReport;
}

export class BatchMappings {
  @prop({ type: [String] })
  additionalFixedValues: string[];

  @prop({ type: [String] })
  otherMappings: string[];
}

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Template extends BaseModel {
  @prop({ required: true })
  name: string;

  @prop({ type: [String], required: true })
  mappingFields: string[]; // each string has format excelColumnName:DeclarationField

  @prop({ ref: 'Declarant' })
  declarants: Ref<Declarant>[];

  @prop({ ref: () => TransactionType })
  transactionType: Ref<TransactionType>;

  @prop({ type: [String] })
  additionalFixedValues: string[]; // each value has format excelColumnName:DeclarationField

  @prop()
  sheetName: string;

  @prop()
  groupByShippingMark: boolean;

  @prop()
  groupLRNByShippingMarks: boolean;

  @prop()
  groupByOrigin: boolean;

  @prop({
    required: true,
    enum: TemplateType,
    default: TemplateType.LOW_VALUE_H7,
    type: String,
  })
  type: TemplateType;

  @prop()
  isExport?: boolean;

  @prop({ default: false })
  deleted: boolean;

  @prop({ _id: false, type: HighValue })
  highValueSettings?: HighValue;

  @prop({ _id: false, type: BatchMappings })
  batchMappings: BatchMappings;

  @prop()
  templateUrl: string;

  @prop()
  templateDynamicPageUrl?: string;

  @prop()
  templateWatermarkUrl: string;
}
