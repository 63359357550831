/* eslint-disable complexity */

import { TransitHouseConsignment } from '@e-origin/shared';

import Icons from '../../../../../../assets';
import { CustomModal } from '../../../../../../components';
import ModalStyle from '../../../../../../styles/modal';
import { HouseForm } from './house-form/house-form';
import * as Style from './house-modal.style';

export interface HouseModalProps {
  houseConsignment?: TransitHouseConsignment;
  editDisabled: boolean;
  editDisabledForConsignment: boolean;
  onHide: () => void;
  onSave: (houseConsignment: TransitHouseConsignment) => void;
  isArrival: boolean;
}

export const HouseModal: React.FC<HouseModalProps> = ({
  houseConsignment,
  editDisabled,
  editDisabledForConsignment,
  onHide,
  onSave,
  isArrival,
}) => {
  const context = houseConsignment ? 'UPDATE' : 'CREATE';
  return (
    <CustomModal width={55} show={true} onHide={onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>{context === 'CREATE' ? 'Create' : 'Update'} House Consignment</ModalStyle.Title>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <HouseForm
        houseConsignment={houseConsignment}
        onSave={onSave}
        onHide={onHide}
        disabled={editDisabled}
        editDisabledForConsignment={editDisabledForConsignment}
        isArrival={isArrival}
      />
    </CustomModal>
  );
};
